<template>
  <div>
    <v-card>
      <v-card-title>{{$t('t.Stripe')}}</v-card-title>
      <v-card-text>
        <v-btn
          v-if="canConnectStripe"
          class="connect-stripe"
          rounded
          justify-center
          color="primary"
          @click="initStripe()"
          v-ripple
          :loading="isWorking"
        >
          <span>{{$t('t.ConnectStripe')}}</span>
        </v-btn>
        <v-btn
          v-else-if="canDisconnectStripe"
          class="connect-stripe"
          rounded
          justify-center
          color="primary"
          @click="disconnectStripe()"
          v-ripple
          :loading="isWorking"
        >
          <span>{{$t('t.DisconnectStripe')}}</span>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    canConnectStripe () {
      return this.companyId && !this.dataIsStripeConnected
    },
    canDisconnectStripe () {
      return this.dataIsStripeConnected
    }
  },
  data () {
    return {
      isWorking: false,
      dataIsStripeConnected: false
    }
  },
  methods: {
    async initStripe () {
      this.isWorking = true
      await this.$http().get(`/core/allocation/stripe/${this.companyId}/init-account`)
        .then(r => {
          window.location.href = r.data
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e)
        }
        )
        .finally(e => {
          this.isWorking = false
        })
    },
    async disconnectStripe () {
      this.isWorking = true

      await this.$http().put(`/core/allocation/stripe/${this.companyId}/disconnect-account`)
        .then(r => {
          this.dataIsStripeConnected = false
          this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.DisconnectStripeConfirmation'))
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', e)
        }
        )
        .finally(e => {
          this.isWorking = false
        })
    }
  },
  props: {
    settings: Object,
    companyId: String,
    isStripeConnected: Boolean
  },
  watch: {
    isStripeConnected: {
      immediate: true,
      handler (v) {
        this.dataIsStripeConnected = v
      }
    }
  }
}
</script>

<style lang="stylus" scoped></style>
